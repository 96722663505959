window.site = window.site || {};

site.template = (function($, _, Mustache) {
  site.templates = site.templates || {};
  site.translations = site.translations || {};

  var defaults = {
    globals: {
      t: site.translations,
      variables: {
        // IE doesn't support location.origin, so...
        site_url: window.location.protocol + '//' + window.location.hostname
      }
    }
  };

  // include config settings from brand common module
  if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.common)) {
    $.extend(defaults.globals.variables, Drupal.settings.common);
  }

  var public = {
    get: function(args) {
      var template = site.templates[args.name];

      // If that didn't work, search for a versioned match of the same template
      // (eg. template_v2)
      if (!template && args.name) {
        for (var key in site.templates) {
          if (site.templates.hasOwnProperty(key)) {
            var matcher = new RegExp(args.name + "_v(\\d+)$");
            if (matcher.test(key)) {
              template = site.templates[key];
              break;
            }
          }
        }
      }

      if (_.isUndefined(template)) {
        console.log('The template ' + args.name + ' cannot be found');
      }

      var rendered = this.render(template, args.data);

      if (_.isFunction(args.callback)) {
        var so = args.callback(rendered);
        if (!_.isUndefined(so)) {
          return so;
        }
      }

      return rendered;
    },

    render: function(template, data) {
      defaults.globals.t = site.translations;
      data = data || {};

      // You can pass just the template as a string if you want:
      if (_.isString(template)) {
        template = {
          content: template,
          data: {}
        };
      }

      var view = $.extend({}, defaults, template.data, data);
      var partials = {};

      if (!_.isUndefined(template.partials)) {
        $.each(template.partials, function(key, name) {
          if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
            console.log('The partial ' + key + ' or ' + name + ' cannot be found');
          }

          var pkey = (!_.isUndefined(site.templates[key])) ? key : name;
          partials[pkey] = site.templates[pkey].content;
        });
      }

      return Mustache.render(template.content, view, partials);
    }
  };

  return public;
})(
  window.jQuery = window.jQuery || function(){},
  window._ = window._ || {},
  window.Mustache = window.Mustache || {}
);

(function($, generic) {

  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch : function(args, event) {
      if (typeof event !== 'undefined' ) {
        event.preventDefault();
      }
      // ColorBox args sent along
      var cboxArgs = {
          'height': 'auto',
          'width' : '768px',
          'margin' : 'auto'
        };
      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides
      // When mobile, override any height/width and set to 100%
      if ($(window).width() <= 768) {
        _.extend(cboxArgs, {height: '100%', width: '100%'});
      }
      // Actual content of the overlay
      if (typeof args.content !== 'undefined') cboxArgs.html = args.content;
      // A custom class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') cboxArgs.className = args.cssClass;
      // Scroll to an anchor, if sent over
      if (typeof args.inPageAnchor !== 'undefined') {
        cboxArgs.onComplete = function() {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        };
      }
      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each( function() {
        var args = {
            cssStyle: {}
          }, // args sent to overlay
          linkClassNames = $(this).attr('class'), // class name sent to colorbox
          linkHref = $(this).attr('href'), // actual href
          linkHrefWithEmbed = linkHref,
          inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
          overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
          try {
            if( !linkHref.match(/[\&\?]embed=1($|&)/)) {
              var urlPathName = urlParts.pathname;

              // For IE issue
              // In IE - Anchor pathname Property returns incorrect path name part of the href attribute value
              // If provided href contains slash in the beginning, and if the pathname property doesn't
              // return with slash, then lets prepend slash
              if (linkHref.indexOf('/') === 0 && urlPathName.indexOf('/') !== 0) {
                urlPathName = '/' + urlPathName;
              }
              linkHrefWithEmbed = urlPathName + (urlParts.search === "" ? "?" : urlParts.search+"&") + "embed=1" + urlParts.hash;

              // Retain original link if it included the protocol.
              if(linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + "//" + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch(e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
        });

      }); // .each()

    }, // initLinks

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb("language");
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }
  };

  ($)(function(){
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });

})(jQuery, window.generic || {});

(function($, generic, site) {

/**
  * Method to grab a cookie and use that to control DOM elements as needed.
  * Handles the setting and getting of the user cookie defined in cookie.name and set in backend.
  * To find where the cookie is set on backend, look to Request::TransactionLocaleHandler.
  * Example cookie structure not signed in:
  *   FE_USER_CART=item_count:1&first_name:&signed_in:0&region_id:0
  * Example cookie structure signed in:
  *   FE_USER_CART=item_count:3&first_name:John&signed_in:1&region_id:0
  * You can set specific functions on page load using events.load or hook into javascript events
  *  by defining them in the events class and adding the event to events.init.
  * The cookie class is used to handle all the cookie functionality such as the setting and getting.
  * This method is meant to be stand alone so should be able to add to a brand without many tweaks.
  * Uses straight javascript so not dependent on a javascript framework except for DOM load.
  * Preferably added to the where ever the globalnav javascript is added within a brand.
  * @memberOf site
*/
site.userInfoCookie = function() {
  // Set global vars here.
  var nodes = {};

  // Private internal cookie class.
  // Leverages generic.cookie to get and set the cookie values.
  var cookie = {
    name: 'FE_USER_CART',
    value: '',
    regEx: function(key) {
      if (!key) {
        return null;
      }
      return new RegExp(key + ':([^;&,}]*)');
    },
    set: function() {
      if (!this.name) {
        return null;
      }
      var userCookie = generic.cookie(this.name);
      this.value = userCookie ? userCookie : '';
    },
    getValue: function(key) {
      var keyVal = this.value.match(this.regEx(key));
      return keyVal ? (keyVal[1] ? keyVal[1] : null) : null;
    },
    setValue: function(key, val) {
      var match  = this.value.match(this.regEx(key));
      var oldValue = match[0];
      var newValue = this.value.replace(match[1], val);
      generic.cookie(this.name, newValue, { path: '/' });
      this.value   = newValue;
    }
  };

  // Private events class that handles all individual events.
  // Add all events in 'init' method so they will get fired on page load.
  // The cart event is commented out but there as an example.
  var events = {
    init: function() {
      this.load();
      //this.cart();
    },
    load: function() {
      _setCartItemsTotal();
    },
    cart: function() {
      /*
      $(document).on('cart:countsUpdated', function() {
        var cartCount = generic.checkout.cart.getTotalItems();
        cookie.setValue('item_count', cartCount);
      });
      */
    }
  };

  /* Additional helper functions below here. */

  // Pulls in the cookie info and updates the DOM;
  var _setCartItemsTotal = function() {
    if (!nodes.cartTotalContainer) {
      return null;
    }

    var valueKey = 'item_count';
    var itemsTotal = cookie.getValue(valueKey) - 0 || 0;
    $.each(nodes.cartTotalContainer, function() {
      $(this).html(itemsTotal || '');
    });
  };

  // BRAND SPECIFIC: Get any DOM nodes and assign them to the global class var nodes. Varies between brands.
  // Helps keep all the brand specific DOM definitions in one spot.
  var _getDomNodes = function() {
    nodes.cartTotalContainer = $('.js-cart_count');
  };

  return {
    init: function() {
      _getDomNodes();
      cookie.set();
      events.init();
    },
    set: function() {
      cookie.set();
    },
    getValue: function(key) {
      return cookie.getValue(key);
    },
    setValue: function(key, val) {
      cookie.setValue(key, val);
    }
  };
}();

// Set the cookie outside of $(document).ready so other scripts can always access it in their $(document).ready:
site.userInfoCookie.set();

})(jQuery, window.generic || {}, window.site || {});

(function($) {
    Drupal.behaviors.countryChooser = {
        attach: function(context, settings) {
            $('.country-chooser__select').on("change", function(obj) {
                window.location = obj.target.value;
            });
        }
    };
})(jQuery);
// This file includes this commit to work with require.js:
// https://github.com/pouipouidesign/Unison/commit/30b1b0d9fcff831f7c4f92952911902f01284b57
// It also has some customizations to work with IE8.

/* global Unison: true */
Unison = (function() {

  'use strict';

  var win = window;
  var doc = document;
  var head = doc.head;
  var eventCache = {};
  var unisonReady = false;
  var currentBP;

  var util = {
    parseMQ : function(el) {
      var str = this.getStyleProperty(el, 'font-family');
      return str.replace(/"/g, '').replace(/'/g, '');
    },
    getStyleProperty: function(el, attr) {
      if (this.isUndefined(win.getComputedStyle)) {
        attr = attr.replace(/-(.)/g, function(match, group1) {
          return group1.toUpperCase();
        });
        return el.currentStyle[attr];
      } else {
        return win.getComputedStyle(el, null).getPropertyValue(attr);
      }
    },
    debounce : function(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        }, wait);
        if (immediate && !timeout) {
          func.apply(context, args);
        }
      };
    },
    isObject : function(e) { return typeof e === 'object'; },
    isUndefined : function(e) { return typeof e === 'undefined'; }
  };

  var events = {
    on : function(event, callback) {
      if ( !util.isObject(eventCache[event]) ) {
        eventCache[event] = [];
      }
      eventCache[event].push(callback);
    },
    emit : function(event, data) {
      if ( util.isObject(eventCache[event]) ) {
        var eventQ = eventCache[event].slice();
        for ( var i = 0; i < eventQ.length; i++ ) {
          eventQ[i].call(this, data);
        }
      }
    }
  };

  var breakpoints = {
    all : function() {
      var BPs = {};
      var allBP = util.parseMQ(doc.querySelector('title')).split(',');
      for ( var i = 0; i < allBP.length; i++ ) {
        var mq = allBP[i].trim().split(' ');
        BPs[mq[0]] = mq[1];
      }
      return ( unisonReady ) ? BPs : null ;
    },
    now : function(callback) {
      var nowBP = util.parseMQ(head).split(' ');
      var now = {
        name : nowBP[0],
        width : nowBP[1]
      };
      return ( unisonReady ) ? (( util.isUndefined(callback) ) ? now : callback(now)) : null ;
    },
    update : function() {
      breakpoints.now(function(bp) {
        if ( bp.name !== currentBP ) {
          events.emit(bp.name);
          events.emit('change', bp);
          currentBP = bp.name;
        }
      });
    }
  };

  if (util.isUndefined(head)) {
    head = document.getElementsByTagName('head')[0];
  }

  win.onresize = util.debounce(breakpoints.update, 100);

  // if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
    unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
    breakpoints.update();
  // } else {
  //   doc.addEventListener('DOMContentLoaded', function(){
  //     unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
  //     breakpoints.update();
  //   });
  // }

  return {
    fetch : {
      all : breakpoints.all,
      now : breakpoints.now
    },
    on : events.on,
    emit : events.emit,
    util : {
      debounce : util.debounce,
      isObject : util.isObject
    }
  };

})();

/**
 * @namespace
 */
var site = site || {};
site.signin = site.signin || {};

(function($, site) {
  Drupal.behaviors.forgot_password = {
  attach: function(context) {

  /**
   * One-time call to collect specific RB Keys used for forget password.
   * @methodOf site.signin
   */

  site.signin.getRBKeys = function() {
    site.signin.rb = generic.rb("error_messages");
    site.signin.forgotPasswordEmailNotFound   = site.signin.rb.get('incorrect_pwremind');
    site.signin.forgotPasswordNoEmailProvided = site.signin.rb.get('session_pw_hint');
    site.signin.forgotPasswordMigratedUser    = site.signin.rb.get('migrated.mobile_account.signin');
  }

  /**
    * This method is used to set up the forget password functionality
    * on the site.
    * Takes the passed element in the DOM and gets the required form
    * nodes and places them within forgotPassArgs object.
    * site.signin.setForgetPassword() is then called if the param resetPassword
    * is set to true.
    * @param {Object} args
    * @param {Object} args.emailNode **REQUIRED** DOM element of either a
    * form element or wrapper element of the email.
    * @param {Object} args.errorListNode **REQUIRED** DOM element used to show
    * password hint or error messaging if hint is not available.
    * @param {Object} args.forgotPasswordLink **REQUIRED** DOM element of the
    * forget password link.
    * @params {element} forget link node set on dom:load
    * @methodOf site.signin
  */
  site.signin.forgotPassword = function(args) {
    if ((args.emailNode.length > 1) || (!args.forgotPasswordLink) || (!args.errorListNode) ) {
      return null;
    }

    site.signin.getRBKeys();

    var errorListNode = args.errorListNode;
    var emailNode = args.emailNode;
    var forgotPasswordLink = args.forgotPasswordLink;
    var forgotPasswordNote = args.forgotPasswordNote;
    var forgotPasswordCopy = $("#lpw-text");
    // content may have been set on server side. If so, do not hide.
    if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length<1) {
        forgotPasswordCopy.hide();
    }

    forgotPasswordLink.bind('click', function(evt) {
      evt.preventDefault();

      forgotPasswordCopy.show();
      var email = site.signin.getEmailAddress(emailNode);

      if (email.length < 1) {
        $('.error_messages').empty();
        $('.error_messages').append("<li class='s' id='signin_error.email_address.'>" + site.signin.forgotPasswordNoEmailProvided + "</li>");

        // remove errors from new user section div
        $('#new-account > .error_messages').empty();
        // Highlight email field if it is empty
        emailNode.addClass('error');

        return null;
      }

      emailNode.removeClass('error');
      site.signin.requestPassword(email);

      // remove errors from new user section div
      $('#new-account > .error_messages').empty();
      return false;
    });
  };

  /**
    * This method is used to reset a users password by submitting a hidden form.
    * @param {email} the user's email address **REQUIRED**
    * @param {actionURL} the page URL of the reset page **REQUIRED**
    * **NOTE**: The error check for if an account exists is handled by the password
    * hint function. The reset is hidden inside the password hint function
    * so no duplicate error checking is needed here.
  */
  site.signin.initResetPassword = function(emailNode) {
    //have to initialise the link here because it isn't on the page until the pw hint method is invoked
    var email = site.signin.getEmailAddress(emailNode);
    var resetPassLink = $('#pwd-reset');
    if (resetPassLink) {
      resetPassLink.bind('click', function(evt) {
        evt.preventDefault();
        site.signin.requestPassword(email);
      });
    }};


  /**
    * This method is used to direct the user to registration.tmpl or password_request.tmpl.
    * The passed values are injected into the genric form before it is submitted.
    * @param {email} the user email that will be passed. **REQUIRED**
    * @param {actionURL} action url used on user submit. **REQUIRED**
    * @param {returnURL} passed when an action is needed after the user
    * has gone to the next template page. **NOT REQUIRED**
    * Example case for returnURL is if the user goes through checkout and registers,
    * the returnURL is used to pass the viewbag action url to the registration page. Once
    * registration form is filled out, user will be brought to viewbag.
    * @methodOf site.signin
  */
  site.signin.submitHiddenSigninForm = function(args) {
    if (!args.actionURL || !site.signin.hiddenForm) {
      return null;
    }
    site.signin.hiddenForm.attr('action', args.actionURL);
    var hiddenEmailNode = $('.js-hidden-email');
    hiddenEmailNode.val(args.email);

    if (args.returnURL) {
      var hiddenReturnNode = $('.js-hidden-return');
      hiddenReturnNode.val(args.returnURL);
    }
    site.signin.hiddenForm.submit();
  };

  /**
    * This method is used to call site.signin.submitHiddenSigninForm by
    * passing the user's email used in the reset form submit.
    * @param {String} the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
    */
  site.signin.requestPassword = function(emailAddr) {
    site.signin.hiddenForm = $('.js-signin-hidden-form');
    if (site.signin.hiddenForm) {
      site.signin.submitHiddenSigninForm({
        email: emailAddr,
        actionURL: '/account/password_request.tmpl'
      });
    }
  }

  /**
    * This method is used to pull the user's email from either a form
    * input or container html tag wrapper (i.e. div, span, etc)
    * @param {String} emailNode the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
  */
  site.signin.getEmailAddress = function(emailNode) {
    if(!emailNode ) return null;
    return emailNode.val();
  }

  }
  };

})(jQuery, site);

var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function($) {

prodcat.data.collectProductIds = function($context) {
  var prodIds = [];
  $('[data-product-id]', $context).each(function() {
    var $this = $(this);
    var prodId = $this.attr('data-product-id');
    // Check if a prodId exists
    if (!prodId) {
      return null;
    };
    var insert = true;
    for (var i = prodIds.length - 1; i > -1; i--) {
      if (prodIds[i] === prodId) {
        insert = false;
        break;
      }
    }
    if (insert) {
      prodIds.push(prodId);
    }
    insert = true;
  });
  return prodIds;
};

/**
 * Retrieves product data from data store. Folds full sku data into product.skus array
 * @param {String} prodId
 */
prodcat.data.getProduct = function(prodId) {
  if (!prodcat.data.store.products[prodId]) {
    return null;
  }
  var prodData = $.extend( true, {}, prodcat.data.store.products[prodId] );
  _.each(prodData.skus, function(skuId, idx) {
    prodData.skus[idx] = prodcat.data.getSku(skuId);
  });
  return prodData;
};

prodcat.data.getSku = function(skuId) {
  skuId = skuId + ''; // Has to be a string to run indexOf
  skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
  var skuData = prodcat.data.store.skus[skuId];
  if (!skuData){
    return null;
  }
  return skuData;
};

/**
 * Sanitize the product data to ensure a consistent data structure
 */
prodcat.data.sanitizeProductData = function(product) {
  // Force the following fields to be an array even if they're originally a string
  var pf = ['IMAGE_L', 'IMAGE_M', 'IMAGE_S', 'IMAGE_XL', 'IMAGE_XM', 'IMAGE_XXL'],
      fk = '';
  for (var i = pf.length; i--;) {
    fk = pf[i];
    if (_.isUndefined(product[fk])) {
      continue;
    }
    product[fk] = (typeof product[fk] === 'string') ? [ product[fk] ] : product[fk];
  }

  return product;
};


$(document).on('prodcat.products.update', function(e, productsArray, deepCopy) {
  prodcat.data.updateProducts(productsArray, deepCopy);
});

prodcat.data.updateProducts = function(productsArray, deepCopy) {
  var self = this;
  deepCopy = (deepCopy === false) ? deepCopy : true;  // do a deep copy of the product data by default
  _.each(productsArray, function(newProd) {
    if (!newProd) {
      return;
    }
    var targetProd = $.extend(deepCopy, {}, newProd);
    var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

    if (_.isArray(newProd.skus)) {
      prodcat.data.updateSkus(targetProd.skus);
      targetProd.skus = _.map(targetProd.skus, function(sku) {
        return sku.SKU_ID;
      });
    }

    var prod = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);

    // Set defaultSku to the first sku if not already set:
    if (_.isUndefined(prod.defaultSku) && prod.skus && prod.skus.length) {
      prod.defaultSku = self.getSku(prod.skus[0]);
    }

    prodcat.data.store.products[targetProd.PRODUCT_ID] = prod;
  });

  $(document).trigger('prodcat.products.updated', prodcat.data.store.products);
};

/**
 * Sanitize the sku data to ensure a consistent data structure
 */
prodcat.data.sanitizeSkuData = function(sku) {
  // Remove any "product" keys from the sku object to prevent recursion errors down the road.
  sku.product = undefined;

  // Force the following fields to be an array even if they're originally a string
  var sf = ['IMAGE_SMOOSH_L', 'IMAGE_SMOOSH_S', 'IMAGE_SMOOSH_XL'],
      fk = '';
  for (var i = sf.length; i--;) {
    fk = sf[i];
    if (_.isUndefined(sku[fk])) {
      continue;
    }
    sku[fk] = (typeof sku[fk] === 'string') ? [ sku[fk] ] : sku[fk];
  }

  return sku;
};

prodcat.data.updateSkus = function(skusArray) {
  _.each(skusArray, function(newSku) {
    newSku = prodcat.data.sanitizeSkuData(newSku);
    var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
    prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
  });
};

prodcat.data.init = function() {
  prodcat.data.store = {
    categories: {},
    products: {},
    skus: {}
  };

  if (typeof page_data === "undefined" || !page_data) {
    return null;
  }

  function _catStore(newCat) {
    var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
    var targetCat = $.extend(true, {}, newCat);
    if (_.isArray(targetCat.products)) {
      prodcat.data.updateProducts(targetCat.products);
    }
    targetCat.products = _.map(targetCat.products, function(prod) {
      return prod.PRODUCT_ID;
    });
    prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
  }

  for (var key in page_data) {
    if (!page_data[key]) {
      continue;
    }
    if (page_data[key].categories && _.isArray(page_data[key].categories)) {
      _.each(page_data[key].categories, _catStore);
    }
    if (page_data[key].products && _.isArray(page_data[key].products)) {
      prodcat.data.updateProducts(page_data[key].products);
    }
    if (page_data[key].product && _.isObject(page_data[key].product)) {
      $(document).trigger('prodcat.products.update', [page_data[key].product]);
      prodcat.data.updateProducts([page_data[key].product]);
    }
  }

  $(document).trigger('prodcat.data.initalized', prodcat.data.store);
};

prodcat.data.pids = prodcat.data.collectProductIds();
// Prevent making an empty call if no prodIds are available.
if (prodcat.data.pids.length > 0) {
  site.onLoadRpc.requests.push({
    method:   "prodcat.querykey",
    getParams: function() {
      var pids = prodcat.data.pids;
      return [{
        products: pids,
        query_key: 'catalog-mpp-volatile'
      }];
    },
    onSuccess: function (r) {
      if (
        !r ||
        !r.result ||
        !r.result.value ||
        !r.result.value.products
      ) {
        return;
      }
  
      var prods = _.compact(r.result.value.products);
      prodcat.data.updateProducts(prods);

      _.each(prods, function(prod) {
        var prodSlctr = "[data-product-id='" + prod.PRODUCT_ID + "']";
        $(prodSlctr).trigger("inv_status_data:updated");
      });

      if (prods.length > 0) { // can't think of a reason why this WOULD be empty, but check, just in case
        // trigger a custom event, letting all who care know that we've updated inventory status data for every product
        $(document).trigger("inv_status_data:finished");
      }

      $(document).trigger('prodcat.data.query.success');
    }
  });
}

prodcat.data.isPaletteMultiSku = function(prodId) {
  var prodData = prodcat.data.getProduct(prodId);
  return !!prodData.isPaletteMultiSku;
};

/**
 * Pull data from page_data and store internally.
 */
Drupal.behaviors.prodcatDataInit = {
  attached: false,
  attach: function() {
    if (!this.attached) {
      prodcat.data.init();
    }
    this.attached = true;
  }
};

})(jQuery);

var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function($, generic) {
/**
 * Adds SKUs to cart.
 */
prodcat.ui.addToCart = function(args) {
  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return;
  }

  var quantity;
  if (typeof args.quantity !== 'undefined') {
    quantity = args.quantity;
  } else {
    quantity = 1;
  }

  var catBaseId = '';
  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
    if (matchResult) {
      catBaseId = matchResult[0];
    }
  }

  args.skus       = args.skus || (_.isString(skuBaseId) ? [skuBaseId] : skuBaseId);
  args.itemType   = args.itemType || 'cart';
  args.QTY        = quantity || args.QTY;
  args.INCREMENT  = 1;  // INCREMENT only needs to be true.

  // Conditionally add a CAT_BASE_ID key to the list of parameters to send
  var cbid = args.CAT_BASE_ID || catBaseId;
  if (cbid.length > 0) {
    args.CAT_BASE_ID = cbid;
  }

  generic.checkout.cart.updateCart({
    params: args,
    onSuccess: function(r) {
      var resultData = r.getData();
      $(document).trigger('addToCart.toggle', [args.$addBtn]);
      $(document).trigger('addToCart.success', [resultData]);
    },
    onFailure: function(ss) {
      var errorObjectsArray = ss.getMessages();
      $(document).trigger('addToCart.toggle', [args.$addBtn]);
      $(document).trigger('addToCart.failure', [errorObjectsArray]);
    }
  });
};

/**
 * Adds a SKU to the user's primary favorites list.
 * @param {Object} args
 * @param {Object} args.skuData a set of key-value pairs describing a SKU
 * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
 * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 */
prodcat.ui.addToFavorites = function(args) {

  var params = {
    _SUBMIT: 'alter_collection',
    action: 'add'
  };

  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return;
  }
  params.SKU_BASE_ID = skuBaseId;

  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
    if (matchResult) {
      params.CAT_BASE_ID = matchResult[0];
    }
  }

  generic.jsonrpc.fetch({
    method : 'rpc.form',
    params: [params],
    onSuccess:function(jsonRpcResponse) {
      var d = jsonRpcResponse.getData();
      var r = d.ac_results[0].result;
      var cr;

      if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
        cr = jsonRpcResponse.getCartResults();
        $(document).trigger('addToWishlist.exists', [cr, args.$el]);

      }
      else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
        cr = jsonRpcResponse.getCartResults();
        $(document).trigger('addToWishlist.success', [cr, args.$el]);
      }
    },
    onFailure: function(ss) {
      var errorObjectsArray = ss.getMessages();
      $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
    }
  });
};

prodcat.ui.grids = (function () {
  var grids = [];
  return $.extend(prodcat.ui.grids || {}, {
    add: function (grid) {
      if (_.indexOf(grids, grid) === -1) { // if we can't find the grid in our list
        grids.push(grid);
      }
    },
    clean: function (args) {
      if (args.before && typeof args.before === "function") {
        args.before(grids);
      }
      site.util.grids.attach(grids);
      if (args.after && typeof args.before === "function") {
        args.after(grids);
      }
      grids = [];
    }
  });
}());

// Default product listeners:

$(document).on('product.init', '.js-product', function() {
  // Check for specified sku to display on page load
  var $product = $(this);
  var isSPP = $product.hasClass('js-product-full');
  var skuBaseId;

  // Check for sku routing in the SPP url
  if (isSPP) {
    var hash = location.hash,
      routeStringAfter = hash.split('#/sku/')[1];
    skuBaseId = (/^\d+$/.test(routeStringAfter)) ? routeStringAfter : $product.data('default-sku-base-id');
  } else if ($product.hasClass('js-quickshop')) {
    skuBaseId = $product.data('sku-base-id');
  }

  var routerActions = {
    changeSku: function(skuID) {
    if (!isSPP) {
      return;
    }
      var skuData = prodcat.data.getSku(skuID);
      if (skuData) {
        skuBaseId = skuID;
      }
    }
  };
  var routes = {
    '/sku/:skuID': routerActions.changeSku
  };
  var router = Router(routes);
  router.init();

  if (!!skuBaseId) {
    $(this).trigger('product.skuSelect', [skuBaseId]);
  }
});

$(document).on('product.updateInvStatus', '.js-product', function() {
  var $addBtn = $('.js-add-to-cart, .js-add-to-bag', this);
  var skuBaseId = $addBtn.data('sku-base-id') || $(this).data('sku-base-id');
  var skuDataL2 = prodcat.data.getSku(skuBaseId);
  var $parentGrid;
  var parentGridOverrideStatus;
  var reorderStatuses = [ // these are the statuses that will trigger a product block to be reordered
    2, // Temporarily Sold Out
    3, // Coming Soon
    7  // Sold Out
  ];
    var hasWaitlist = Drupal.settings.common && Drupal.settings.common.has_waitlist;
    var waitlist_notify_inv = (Drupal.settings.common && Drupal.settings.common.waitlist_notify_inv) || '3,2,7';
    var $waitlistBtn = $('.js-add-to-waitlist', this);
  var reorderTest = function (l2Cache) {
    return _.contains(reorderStatuses, parseInt(l2Cache.INVENTORY_STATUS));
  };
  var reorderSoldOut = function (skuDataL2, el) {
    var $el = $(el);

    if (!_.isArray(skuDataL2)) {
      skuDataL2 = [ skuDataL2 ];
    }

    if (_.every(skuDataL2, reorderTest)) {
      $el.closest('.js-grid-item').appendTo($el.closest('.js-grid-item').parent());
      prodcat.ui.grids.add($el.closest('.js-grid').get(0));
    }
  };

  if (!$addBtn.length) {
    return;
  }

  $parentGrid = $(this).parents('.js-product-grid');
  parentGridOverrideStatus = $parentGrid.data('sold-out-reordering-override');
  // Reordering sold out products (inv. status 7), to be last in display order on MPPs/anywhere they appear in a grid
  if ($parentGrid.is('.js-sold-out-reordering') && parentGridOverrideStatus !== 'off' || parentGridOverrideStatus === 'on') {
    reorderSoldOut(prodcat.data.getProduct($(this).data('product-id')).skus, this);
  }

    waitlist_notify_inv = $.map(waitlist_notify_inv.split(','), Number);
    if (skuBaseId && skuDataL2) {
      if (skuDataL2.isShoppable) {
        $addBtn.removeClass('button--disabled button--inverted').data('disabled', false);
      } else {
        $addBtn.addClass('button--disabled button--inverted').data('disabled', true);
        $addBtn.removeClass('hidden');
        $waitlistBtn.removeClass('active js-waitlist-enabled').addClass('hidden');
      }
      if (hasWaitlist) {
        if ($.inArray(skuDataL2.INVENTORY_STATUS, waitlist_notify_inv) > -1) {
          $(document).trigger('product.waitlist.init', skuDataL2);
        } else {
          $(document).trigger('product.waitlist.reset', skuDataL2);
        }
      }
    }

    $(this).trigger('product.updateInvStatusFinished');
});

$(document).on('product.updateInvStatusFinished', function() {
  prodcat.ui.grids.clean({
    before: function (grids) {
      _.each(grids, function (grid) {
        var $grid = $(grid),
          hasQuickshop = $grid.hasClass('product-grid--quickshop'),
          // not currently using, but can distinguish on a per grid basis which have inline quickshops
          isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.reset($grid);
        }
      });
    },
    after: function (grids) {
      _.each(grids, function (grid) {
        var $grid = $(grid),
          hasQuickshop = $grid.hasClass('product-grid--quickshop'),
          // not currently using, but can distinguish on a per grid basis which have inline quickshops
          isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.init($grid);
        }
      });
    }
  });
});

$(document).on('product.quickshopInit', function(e, methods) {
  prodcat.ui.quickshop = _.extend(prodcat.ui.quickshop || {}, methods);
});

$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  $(this).data('sku-base-id', skuBaseId);
  $('.js-add-to-cart, .js-add-to-bag', this).data('sku-base-id', skuBaseId);
  $('.js-add-to-favorites', this).data('sku-base-id', skuBaseId);

  $(this).trigger('product.updateInvStatus');
});

$(document).on('inv_status_data:updated', '.js-product', function() {
  $(this).trigger('product.updateInvStatus');
});

$(document).on('inv_status_data:finished', function() {
  $(this).trigger('product.updateInvStatusFinished');
});

// SPP links open in new window if spp_in_new_window=true in config
if (Drupal.settings.common && Drupal.settings.common.spp_in_new_window) {
  $(document).on('click', '.js-spp-link', function() {
    $(this).attr('target', '_blank');
  });
}

$(document).on('click', '.js-add-to-cart, .js-add-to-bag', function(e) {
  e.preventDefault();
  var $addBtn = $(this);
  if ($addBtn.data('disabled')) {
    return;
  }
  var skuBaseId = $addBtn.data('sku-base-id');
  if (!skuBaseId || skuBaseId.length < 1) {
    return;
  }

  // Trigger the button toggle event to show loading message until all this code and RPC call are complete.
  $(document).trigger('addToCart.toggle', [$addBtn]);

  // Account for adding multiple skus to bag
  var args = {};
  skuBaseId = String(skuBaseId);
  if (skuBaseId.indexOf(',') >= 0) {
    // clean the string
    skuBaseId = skuBaseId.replace(/(\s|\r\n|\n|\r)/gm, '');
    args.skuBaseId = skuBaseId.split(',');
    if (skuBaseId.slice(-1) === ',') {
      args.skuBaseId.pop();
    }
  }
  else {
    args.skuBaseId = skuBaseId;
  }

  var quantity = $addBtn.data('qty');
  if (!!quantity) {
    args.quantity = quantity;
  }

  // Replenishment updates when sku is refillable and enable_replenishment=true in config.
  //  (currently applicable only when adding one item at a time)
  if (_.isString(args.skuBaseId) && Drupal.settings.common && Drupal.settings.common.has_replenishment) {
    var skuDataL2 = prodcat.data.getSku(args.skuBaseId);
    if (skuDataL2.REFILLABLE) {
      var frequency = $addBtn.attr('data-replenishment');
      if (!!frequency) {
        args.REPLENISHMENT_FREQ = frequency;
      } else {
        args.REPLENISHMENT_FREQ = 0;
      }
      args.action       = 'add';
      args.itemType     = 'replenishment';
      args.add_to_cart  = 1;
    }
  }

  args.$addBtn = $addBtn;

  prodcat.ui.addToCart(args);
});

/*
 ***********************
 * Add-to-favorites button
 ***********************
 */

$(document).on('click', '.js-add-to-favorites', function(e) {
  e.preventDefault();
  var skuBaseId = $(this).data('sku-base-id');
  prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $(this)});
});

/*
 ***********************
 * display cross-sell products by sku
 ***********************
 */
$(document).on('product.skuSelect product.skuDisplay', '.js-product', function(e, skuBaseId) {
  $(this).trigger('product.updateCrossSells', [skuBaseId]);
  $(this).trigger('product.updateSkuRoute', [skuBaseId]);
//  $(this).trigger('product.updateText', [skuBaseId]);
});

$(document).on('product.updateCrossSells', '.js-product', function(e, skuBaseId) {
  if (!$(this).hasClass('js-product-full')) return;
  var $crossSellContainer = $('.js-spp-cross-sells-container');
  var $crossSellMoreProducts = $('.js-spp-cross-sells-more-products');
  if ($crossSellMoreProducts.length == 0) return;
  var skuData = prodcat.data.getSku(skuBaseId);
  if (!skuData || !skuData.worksWith || skuData.worksWith.length == 0) return;
  var wwSkusAll = (page_data['works_with_skus'] && page_data['works_with_skus'].skus) ? page_data['works_with_skus'].skus : {};
  if (wwSkusAll.length == 0) return;
  var wwSkus = [];
  var worksWithSkusLength = skuData.worksWith.length;
  // get worksWith product codes
  for (var i = 0; i < worksWithSkusLength; i++) {
    if (skuData.worksWith[i]) {
      // find sku by product code
      var wwSku = _.find(wwSkusAll, function(sku) {
        if (sku && sku.PRODUCT_CODE) {
          return sku.PRODUCT_CODE === skuData.worksWith[i];
        }
      });
      if (wwSku && typeof wwSku !== 'undefined' && wwSku.DISPLAY_STATUS) {
        // add product data that will be needed in the sku object
        var wwProd = prodcat.data.getProduct(wwSku.PRODUCT_ID);
        if (wwProd && (typeof wwProd !== 'undefined')) {
          wwSku.url = wwProd.url;
          wwSku.PROD_RGN_NAME = wwProd.PROD_RGN_NAME;
        }
        wwSkus.push(wwSku);
      }
    }
  }
  if (wwSkus.length > 0) {
    var product_cross_sell_grid = site.template.get({
      name: 'product_cross_sell_grid',
      data: {
        works_with_skus : wwSkus,
        sku_as_product: true,
        works_with_sku_as_product: true,
        is_carousel: true,
        hide_quickshop: true,
      }
    });

    $crossSellContainer.removeClass('hidden');
    $crossSellMoreProducts.fadeTo('slow', 0, 'linear', function() {
      $crossSellMoreProducts.html(product_cross_sell_grid);
      $crossSellMoreProducts.trigger('product.updateCrossSells.finished');
      // SPP links within cross-sell
      $crossSellMoreProducts.on('click', '.js-spp-link', function() {
        window.location.assign(this.href);
        window.location.reload(); // workaround for urls with hashes. @TODO: finesse cases where we can change sku without reloading
      });
    }).fadeTo('slow', 1, 'linear');
  } else {
    $crossSellContainer.addClass('hidden');
  }
});

$(document).on('product.updateText', '.js-product', function(e, skuBaseId) {
  var textFields = [
    { selector: 'js-product-size', field: 'PRODUCT_SIZE' },
    { selector: 'js-product-price', field: 'formattedPrice' },
    { selector: 'js-sku-shade-name', field: 'SHADENAME' },
    { selector: 'js-sku-shade-description', field: 'SHADE_DESCRIPTION' }
  ];
  var skuData = prodcat.data.getSku(skuBaseId);
  var $productEl = $(this);

  _.each(textFields, function(el) {
    var $el = $( '.' + el.selector, $productEl);
    if ($el.length < 1) {
      return;
    }
    $el.html(skuData[el.field]);
  });
});


// @TODO: This probably needs to be smarter, giving a specific message to the user,
//        if this is something they've already favorited. Also, the already favorited
//        products should have their heart icon filled in on page load, somehow (currently,
//        they're not).
$(document).on('addToWishlist.success addToWishlist.exists', function(event, cr, $el) {
  // Set icon to heart--selected, in the wake of a successful favoriting (even if it was already a favorite)
  // $el.find('.icon').removeClass('icon--heart').addClass('icon--heart--selected');
  $el.find('.js-add-to-favorites-label').hide();
  $el.find('.js-add-to-favorites-label-success').show();
});

// @TODO: This probably needs to be nicer, not just dumping errors to an alert
// box.
$(document).on('addToCart.failure addToWishlist.failure', function(event, errorObjectsArray) {
  // Escape any html in the alert box.
  var prodAddedMsg = $('<div/>').html(errorObjectsArray[0].text).text();
  alert(prodAddedMsg);
});


/**
 * Toggles the visibility of an add-to button, and its sibling loading message
 */
$(document).on('addToCart.toggle', function(event, $addBtn) {
  if (!$addBtn || $addBtn.length < 1) {
    return;
  }

  $addBtn.toggleClass('hidden');

  var loadingDiv = $addBtn.siblings('.js-loading-message').eq(0);

  if (loadingDiv && loadingDiv.length > 0) {
    loadingDiv.toggleClass('hidden');
  }
});

$(document).on('product.updateSkuRoute', '.js-product', function(e, skuBaseId) {
  var $product = $(this);
  var skuData = prodcat.data.getSku(skuBaseId);
  var routeString = _.result(skuData, 'SKU_BASE_ID'); // verify valid value

  if (!!routeString) {
    var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
    var $sppLinks = $('.js-spp-link', $product);

    $sppLinks.each(function() {
      $(this).attr('href', prodData.url + '#/sku/' + routeString);
    });

    if ($product.hasClass('js-product-full')) {
      var routeWithQueryString = window.location.hash;
      if (routeWithQueryString.indexOf("?") >= 0) {
        routeString += '?' + routeWithQueryString.split('?')[1];
      }
      history.replaceState({}, routeString, '#/sku/' + routeString);
    }
  } else {
    location.hash = '';
  }
});

/**
 * Wire up behavior on page-load according to js- classnames.
 */
Drupal.behaviors.prodcatUiInit = {
  attach: function(context) {
    $('.js-product', context).trigger('product.init');
    $(document).trigger('products.init-finish');
  }
};

})(jQuery, window.generic = window.generic || {});

(function($) {

'use strict';

Drupal.behaviors.selectBox = {
  attach: function(context) {
    $('.selectBox:not(.selectBox-attached)', context).each(function() {
      $(this).addClass('selectBox-attached').selectBox({
        mobile: $(this).hasClass('selectBox--yes-even-for-mobile')
      });
    });
  },

  // Use this instead of direclty invoking $('.selectBox').selectBox('refresh').
  // The plugin doesn't take into account the fact that the selectBox may be
  // disabled for touch and will blow up if it is.
  refresh: function($selects) {
    $selects.each(function() {
      var control = $(this).data('selectBox-control');
      if (control && control.length) {
        $(this).selectBox('refresh');
      }
    });
  }
};

})(jQuery);

var site = site || {};
site.template = site.template || {};

(function($) {

Drupal.behaviors.offers = {
  linkedBasedOffer: function() {
    if ($.cookie('offer_info') == null || $.cookie('offer_info').length == 0) {
      return;
    }
    var offer_code = $.cookie('offer_info').split(":")[1];
    site.onLoadRpc.requests.push({
      method: 'offer.linkedOfferState',
      params: [offer_code],
      onSuccess: function(data) {
        var message = data.result.value.linked_offer.offer_message;
        var template = offer_code;
        var rendered = site.template.get({
          name: template,
          data: {message : message}
        });
        generic.overlay.launch({
          content: rendered
        });
      },
      onError: function(data) {}
    });
  },

  attach: function(context, settings) {
    if (this.attached) {
      return;
    }
    this.attached = true;

    this.linkedBasedOffer();
  },

  attached: false
};

})(jQuery);

/**
 * Generic expander functionality
 * case for basic expanding menu/ul
 *
 * Expanding Menu Usage / example markup:
 *
 *  <ul class="collapsible-menu">
 *    <li class="collapsible-menu__parent">
 *      <h3 class="collapsible-menu-trigger js-collapsible-menu-trigger">Parent Header</h3>
 *      <div class="collapsible-sub-menu">
 *        <ul class="">
 *          <li class=""><a class="" href="#">Link 1</a></li>
 *          <li class=""><a class="" href="#">Link 2</a></li>
 *          <li class=""><a class="" href="#">Link 3</a></li>
 *        </ul>
 *      </div>
 *    </li>
 *  </ul>
 *
 * static link variant inside menu
 *
 *  <ul class="collapsible-menu cs-menu"><li>
 *  		<h3 class="collapsible-menu__static cs-menu__static"><a href="/customer-service-contact-us">Contact Us</a></h3>
 *  	</li>
 *  </ul>
 *
 * Styles applied:
 * .collapsible-menu__parent - plus when closed, minus when open
 * .collapsible-sub-menu - closed by default
 * additional BEM classes can be added for styling
 *
 *
 * Expanding Mobile Block Usage / example markup:
 *  <section class="content-container collapsible-block">
 *    <a class="collapsible-block__anchor" name="cs-orders-section1" id="cs-orders-section4"></a>
 *
 *    <header class="collapsible-block__header clearfix">
 *      <h4 class="collapsible-block__title collapsible-block__title--pc mobile-hidden">Order Cancellations</h4>
 *      <div class="collapsible-block__title collapsible-block__title--mobile pc-hidden js-collapsible-block-trigger">
 *        <span>Order Cancellations</span>
 *        <i class="collapsible-block__icon"></i>
 *      </div>
 *    </header>
 *
 *    <div class="collapsible-block__content">
 *      <div class="clearfix"></div>
 *      <div class="collapsible-block__content-tout clearfix">
 *        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non gravida quam. Ut sed ultricies neque. Quisque pulvinar turpis vel magna iaculis vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec posuere interdum purus, quis porttitor dolor porta ac.
 *      </div>
 *
 *    </div>
 *  </section>
 */

 (function($){

  /**
   * Basic collapsible-menu behavior. Used in custom markup.
   */
  Drupal.behaviors.collapsibleMenu = {
    attach: function(context) {
      $('.js-collapsible-menu-trigger', context).on('click', function() {
        var $parentGrid = $(this).closest('.js-grid');
        $(this).closest('.collapsible-menu__parent').toggleClass('collapsible-menu__parent--expanded');
        // if we are inside a grid, recalculate heights on click
        if ($parentGrid.length) {
          var $items = $parentGrid.find('.js-grid-item');
          var colCountData = $parentGrid.data('grid-items-per-row');
          site.util.grids.equalHeightsRows($items, colCountData);
        }
      });
    }
  };


  /**
   * Basic collapsible-block behavior.
   */
  Drupal.behaviors.collapsibleBlock = {
    attach: function(context) {

      var $block = $('.collapsible-block', context);
      var $trigger = $block.find('.js-collapsible-block-trigger');

      $block.each(function() {

        if ($(this).hasClass('collapsible-block--mobile-collapsed')) {
          $(this).addClass('collapsible-block--alt');
        }

        // default expansion
        $trigger.on('click', function() {
          $(this).closest('.collapsible-block').toggleClass('collapsible-block--alt');
        });

      });

    }
  };

})(jQuery);

/**
 * Adaptive Placeholders
 */

(function($) {
  'use strict';

  Drupal.behaviors.adaptivePlaceholders = {
    attached: false,

    labelMode: function($input) {
      $input.addClass('js-label-mode');
      $input.removeClass('js-placeholder-mode');
    },

    placeholderMode: function($input) {
      $input.removeClass('js-label-mode');
      $input.addClass('js-placeholder-mode');
    },

    toggleMode: function($input) {
      var self = this;

      if ($input.val() === '') {
        self.placeholderMode($input);
      } else {
        self.labelMode($input);
      }
    },

    bindEvents: function($input) {
      var self = this;

      // swap out placeholder/label classes on focus in or out
      $input.on('focusin', function() {
        self.labelMode($input);
      });

      $input.on('focusout', function() {
        self.toggleMode($input);
      });

      $input.on('change', function() {
        self.toggleMode($input);
      });
    },

    setupDOM: function($inputs) {
      var self = this;

      $inputs.each(function() {
        var $input = $(this);
        if (!$input.hasClass('adpl--processed')) {
          var $label = $input.siblings('label');
          var placeholder = $input.attr('placeholder') || $label.attr('placeholder');

          // input needs a placeholder
          if (!placeholder) {
            return true;
          }

          // if label exists
          if ($label.length > 0) {
            $label.remove(); // hold in space
          } else {
            // if label does not exist, build it
            var id = $input.attr('id');
            if (!!id) {
              $label = $('<label class="label" for="' + id + '">' + placeholder + '</label>');
            } else {
              // if there is no label, and no id on the input, then we cannot proceed
              return true;
            }
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('placeholder')) {
            $label.attr('placeholder', placeholder);
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('alt')) {
            $label.attr('alt', placeholder);
          }

          // ensure that label contains an inner span.label-content wrapping the text
          if ($label.find('span.label-content').length < 1) {
            $label.wrapInner('<span class="label-content"></span>');
          }

          // position the label after the input, required for proper z-index
          $label.insertAfter($input);

          // cleanup inputs
          if ($input.attr('id') == 'google_autocomplete') {
            // google_autcomplete can't have its placeholder attribute removed without
            // it reverting to show the default, so use empty string instead
            $input.attr('placeholder','');
          } else {
            $input.removeAttr('placeholder');
          }

          // set states, bind events
          self.placeholderMode($input);
          self.bindEvents($input);
          self.toggleMode($input);

          // add CSS class for styling
          $input.addClass('adpl--processed');
        }
      });
    },

    attach: function(context, settings) {
      var self = this;

      if ($('html').hasClass('no-placeholder')) {
        return;
      }

      var $inputs = $('input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea', context).not('.no-adpl');
      self.setupDOM($inputs);
    }
  };
})(jQuery);

(function($, site) {

site.videos = {

  open: function(opts) {
    var provider = !_.isEmpty(opts.provider) ? opts.provider : '';

    if (provider === 'zentrick') {
      this.openZentrick(opts);
    }
    else if (provider === 'youtube') {
      this.openYouTube(opts);
    }
    else if (provider === 'vimeo') {
      this.openVimeo(opts);
    }
    else if (provider === 'html5') {
      this.openHTML5(opts);
    }
    else if (provider === 'elc-video') {
      this.openHTML5(opts);
    }
    else {
      console.log('Video provider not yet supported.');
    }
  },

  openZentrick: function(opts) {
    var publicationId = opts.zentrickId;
    var context = !_.isEmpty(opts.context) ? opts.context : document;
    var className = opts.className ? opts.className : '';
    if (!!site.isZentrickPlayerAPIReady && !_.isEmpty(publicationId)) {

      var content = '<div class="js-content-block-video-placeholder"></div>';
      var options = {
        content: content,
        className: 'colorbox--video ' + className,
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768
      };

      // Remove existing videos
      $('.zentrick-video').remove();

      // Create a new video container element
      var id = 'p' + +new Date();
      var $videoContainer = $('<div></div>').attr({
        'class': 'hidden zentrick-video',
        'id':    id
      });
      $videoContainer
        .append( $('<div />').attr('class', 'embed-container').append( $('<div />').attr('id', id + '-inner') ) )
        .appendTo('body');

      // Initialize the player
      site.zentrickPlayer = new zentrick.Player(
        id + '-inner',
        publicationId,
        { html5: 1 }
      );

      // Add our new video player to an overlay
      options.content = '<div class="js-content-block-video-placeholder">' + $('#' + id).html() + '</div>';
      options.fastIframe = false;

      generic.overlay.launch(options);
    }
    else {
      console.log('Zentrick not ready or missing publication id.');
    }
  },

  openYouTube: function(opts) {
    var ytId = opts.youTubeId;
    var className = opts.className ? opts.className : '';
    var context = !_.isEmpty(opts.context) ? opts.context : document;

    var content = '<div class="embed-container"><div class="js-content-block-video-placeholder"></div></div>';
    var options = {
      content: content,
      className: 'colorbox--video ' + className,
      height: 'auto',
      width: '100%',
      maxWidth: 1024,
      maxHeight: 768,
      onComplete: function() {
        var _this = this;
        _this.$cbox = $('#colorbox.colorbox--video', context);
        var $slidesvideoautoplay = $('.js-basic-carousel', context);
        var autoplay = $slidesvideoautoplay.data('slides-video-autoplay');
        if (typeof autoplay == 'undefined') {
          autoplay = true;
        } else {
          autoplay = false;
        }
        function createVideoPlayerDiv($elem, i) {
          var id = $elem.attr('class') + '-' + i;
          $elem
            .parent()
            .append('<div />')
            .children('div')
            .attr('id', id);
          return id;
        }

        function addVideo(ytId, iframeId) {
          // https://developers.google.com/youtube/player_parameters#Parameters
          new YT.Player(iframeId, {
            height: '315',
            width: '560',
            videoId: ytId,
            playerVars: {
              modestbranding: 1,
              controls: 1,
              showinfo: 0,
              rel: 0,
              autoplay: autoplay
            },
            events: {
              'onReady': function() {
                // when the video player is ready
              },
              'onStateChange': function() {
                // when the player's state changes
              }
            }
          });
        }

        // Create a new div for the player to take over inside of the colorbox
        var iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', _this.$cbox), Math.floor((+new Date()) / 1000));

        // Remove our empty container
        $('.js-content-block-video-placeholder', _this.$cbox).remove();

        // Instantiate the video
        addVideo(ytId, iframeId);

      }
    };

    generic.overlay.launch(options);
  },

  openHTML5: function(opts) {
    var content = '<div class="embed-container">' + opts.content + '</div>';
    var className = opts.className ? opts.className : '';
    var context = !_.isEmpty(opts.context) ? opts.context : document;

    generic.overlay.launch({
      content: content,
      className: 'colorbox--video ' + className,
      height: 'auto',
      width: '100%',
      maxWidth: 1024,
      maxHeight: 768,
      onComplete: function() {
        var _this = this;
        _this.$cbox = $('#colorbox.colorbox--video', context);
        $('#cboxLoadedContent', _this.$cbox).css({overflow: 'hidden'});
        $('video', _this.$cbox).css({width: '100%'});
        $('video', _this.$cbox).get(0).play();
      }
    });
  },

  openVimeo: function(opts) {
    var vimeoId = opts.vimeoId;
    var className = opts.className ? opts.className : '';
    var url = '/kilian-embed/vimeo/' + vimeoId;
    var context = !_.isEmpty(opts.context) ? opts.context : document;

    $.ajax(url, {
      success: function(data) {
        var html = data['html'];

        var content = '<div class="embed-container">' + html + '</div>';

        generic.overlay.launch({
          content: content,
          className: 'colorbox--video ' + className,
          height: 'auto',
          width: '100%',
          maxWidth: 1024,
          maxHeight: 768,
        });
      },
    });

    return;
  }
};

  var videoBlock = {
    setup: function($elements, context) {
      var $modules = $elements;

      $modules.each(function() {
        var $module = $(this);
        var $play = $('.js-play', $module);
        var provider = $module.data('video-provider');
        var isYoutube = (provider === 'youtube');
        var isZentrick = (provider === 'zentrick');
        var isHTML5 = (provider === 'html5');
        var isVimeo = (provider === 'vimeo');
        var isELCVideo = (provider === 'elc-video');
        var className = $module.data('videoClass');

        $play.once().click(function(event) {
          event.preventDefault();

          var opts = {
            context: context,
            provider: provider,
            className: className,
          };

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          }
          else if (!!isVimeo) {
            opts.vimeoId = $module.data('vimeo-id');
          }
          else if (!!isZentrick) {
            opts.zentrickId = $module.data('zentrick-id');
          }
          else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          }
          else if (!!isELCVideo) {
            opts.content = $module.find('video').attr({'controls': '', width: '', height: ''}).wrap('<div />').parent().html();
          }

          site.videos.open(opts);
          return false;
        });
      });
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */

  $(document).on('youtubeIframeAPI.loaded', function() {
    var $elements = $('.js-video').filter("[data-video-provider='youtube']");
    if ($elements.length) {
      videoBlock.setup( $elements, document );
    }
  });

  $(document).on('zentrick:loaded', function() {
    var $elements = $('.js-video').filter("[data-video-provider='zentrick']");
    if ($elements.length) {
      videoBlock.setup( $elements, document );
    }
  });

  var $elements = $('.js-video').filter("[data-video-provider='elc-video']");
  if ($elements.length) {
    videoBlock.setup( $elements, document );
  }

  var $elements = $('.js-video').filter("[data-video-provider='html5']");
  if ($elements.length) {
    videoBlock.setup( $elements, document );
  }

  var $elements = $('.js-video').filter("[data-video-provider='vimeo']");
  if ($elements.length) {
    videoBlock.setup( $elements, document );
  }

})(jQuery, site);

/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */


(function($){

  Drupal.behaviors.clickable = {
    attach: function(context, settings) {
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function() {
        if (window.location.hash) {
          history.replaceState('', '', $(this).data('clickable')); // fix for hitchhiking hashes
        }
        window.location.href = $(this).data('clickable');
      });
    }
  };

})(jQuery);

 (function($){

  var $block = $('.footer');
  var $menu_level1 = $block.find('.expanded');
  var $form_signup = $block.find('.js-email-signup');
  var $menu_signup = $block.find('.site-email-signup__copy');

  $menu_level1.on('click', function() {
    var $icon = $(this).find('.icon');
    var $menu_level2 = $(this).find('.menu--lvl-2');
    if ($icon.hasClass('icon--caret--down')) {
      $icon.removeClass('icon--caret--down').addClass('icon--caret--up');
    }else {
      $icon.removeClass('icon--caret--up').addClass('icon--caret--down');
    }
    $menu_level2.toggle();
  });

  $menu_signup.on('click', function() {
    var $ms = $(this);
    var $icon = $ms.find('.icon');
    var $fields = $form_signup.find('.site-email-interest').length > 0 ? $form_signup.find('.site-email-interest') : $form_signup.find('.site-email-signup__wrapper');
    var $button = $form_signup.find('.site-email-btn-common');
    $ms.toggleClass('site-email-signup');
    if ($icon.hasClass('icon--caret--down')) {
      $icon.removeClass('icon--caret--down').addClass('icon--caret--up');
    } else {
      $icon.removeClass('icon--caret--up').addClass('icon--caret--down');
    }
    $fields.toggle();
    $button.toggle();
  });

})(jQuery);

(function($, site, generic) {

site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

site.onLoadRpc.init = function() {
  // User stuff
  var signedIn = (site.userInfoCookie.getValue('signed_in') - 0 === 1);
  if (signedIn) {
    site.onLoadRpc.requests.push({
      method:   'user.fullData',
      params:   [{}],
      onSuccess : function(data) {
        // This is for the Phone Order app to recognize values on Drupal pages
        if (!data || !data.result) {
          return;
        }

        var val = data.result.value;

        if ((val.csr_email !== undefined) && (val.csr_email !== null)){
          $('#csr_header_holder').removeClass('hidden');
        }

        // Set a body class attribute based on user logged in status
        $('body').addClass( (val.signed_in === 1) ? 'elc-user-state-logged-in' : 'elc-user-state-anonymous' );

        // Populate user data
        $('[data-pg-object="user"] > [data-pg-prop], [data-pg-object="user"][data-pg-prop]').each(function() {
          var $me = $(this);
          $me.html(val[$me.attr('data-pg-prop').toLowerCase()]);
        });

        // generic.user.setUser(val);

        $(document).trigger('user.loaded', val);
      },
      onFailure : function() {}
    });
  }
  else {
    // generic.user.setUser({});
    $('body').addClass('elc-user-state-anonymous');
    $(document).trigger('user.loaded', {});
  }
};

/*
 * site.onLoadRpc.requests - a global array of RPC request objects
 * must be initialized pre-DOM-load and formatted like this:
 * [
 *     {
 *         "method":   "user.json",
 *         "params":   [{}],
 *         "getParams" : function () { return [{}] },  * one of 'params' or 'getParams' is required
 *         "onSuccess" : function () { },
 *         "onFailure" : function () { }
 *     }
 * ]
 */
site.onLoadRpc.fetch = function() {
  var requests = site.onLoadRpc.requests || [];
  var rLen     = requests.length;
  var queryVals = [];

  for (var i = 0, len = rLen; i < len; i++) {
    var postMethod = requests[i].method || 'rpc.form';

    if (typeof requests[i].getParams === 'function') {
      requests[i].params = requests[i].getParams();
    }

    queryVals[i] = {
      method: postMethod,
      params: requests[i].params,
      id: i + 1
    };
  }

  if (!queryVals.length) {
    return;
  }

  var successHandler = function(data) {
    for (var i = 0, len = rLen; i < len; i++) {
      var fn = requests[i].onSuccess;
      if (typeof fn !== 'function') {
        continue;
      }
      fn( data[i] );
    }
  };

  var url = generic.jsonrpc.url || '/rpc/jsonrpc.tmpl';
  var options = {};

  // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses
  // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
  // This snippets searches for such entries and adds 'em to the request url.
  var url_params = '';
  $(queryVals).each(function() {
    if (this.params[0].url_params) {
      if (this.params[0].url_params.charAt(0) === '&') {
        url_params += this.params[0].url_params;
      }
      else {
        url_params += '&' + this.params[0].url_params;
      }
    }
  });
  if (url_params !== '') {
    url += '?' + url_params.substring(1);
  }

  options.data = $.param({JSONRPC: JSON.stringify(queryVals)});

  options.type = 'POST';
  options.success = function(data, textStatus, response) {
    successHandler(data, textStatus, response);
  };
  options.error = function(jqXHR, textStatus, errorThrown) { console.log(jqXHR, textStatus, errorThrown); };
  $.ajax(url, options);
};

$(function() {
  $(document).trigger('onLoadRpc.fetch');
  // Set user cookie
  site.userInfoCookie.init();
  site.onLoadRpc.init();
  site.onLoadRpc.fetch();

    if (window.location.hash === '#sign-in') {
      setTimeout(function () {
        $('#return-user-link').trigger('click');
      }, 500);
    }
    if (window.location.hash === '#new-account') {
      setTimeout(function () {
        $('#new-account-link').trigger('click');
      }, 500);
    }
});

})(jQuery, window.site || {}, window.generic || {});
